<template>
  <div class="flex flex-row factbox-item">
    <table aria-label="table-factbox-pricing" class="text-sm text-white">
      <thead>
        <tr class="font-bold">
          <th
            v-for="(headDef, index) in headers"
            :key="index"
            class="whitespace-nowrap pr-4 last:pr-0 text-center first:text-left"
            :class="headDef.columnClasses"
          >
            {{ headDef.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index" class="pt-2">
          <td
            v-for="(headDef, indexCell) in headers"
            :key="indexCell"
            class="pr-4 last:pr-0 first:pr-4"
          >
            <div
              :id="getTooltipId(item, headDef)"
              :class="`${getClassString(headDef, item)} ${
                headDef.onShowTooltip ? 'cursor-pointer' : ''
              } ${indexCell > 0 ? 'justify-end' : ''}`"
              class="flex items-start w-full"
            >
              {{
                headDef.formater
                  ? headDef.formater(item, headDef.columnDef)
                  : item[headDef.columnDef]
              }}
            </div>
          </td>
        </tr>
      </tbody></table
    >
  </div>
</template>

<script setup lang="ts">
import tippy, { followCursor, hideAll } from 'tippy.js'
import type { FactBoxHeader } from './definitions'

const props = defineProps<{
  headers: FactBoxHeader[]
  data: any[]
}>()

const getTooltipId = (data: any, header: FactBoxHeader) => {
  if (!header.onShowTooltip) return

  return header.onShowTooltip(data).id
}

onMounted(() => {
  for (const item of props.data) {
    for (const header of props.headers) {
      tooltipFactory(item, header)
    }
  }
})

const tooltipFactory = (data, headerDef: FactBoxHeader) => {
  if (!headerDef.onShowTooltip) return

  const { id, content } = headerDef.onShowTooltip(data)

  tippy(`#${id}`, {
    placement: 'bottom',
    theme: 'weight',
    arrow: true,
    followCursor: true,
    plugins: [followCursor],
    allowHTML: true,
    onShow: (instance) => {
      instance.setContent(`${content.outerHTML}`)
    },
  })
}

onBeforeUnmount(() => {
  hideAll()
})

const getClassString = (headerDef: FactBoxHeader, data: any) => {
  const classes = headerDef.classes

  if (typeof classes === 'string') return classes

  if (typeof classes === 'function') return classes(data, headerDef.columnDef)
}
</script>

<style scoped>
.price-down::after {
  background-color: theme('colors.s-red.300');
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  mask: url('~/assets/icons/ChevronDown.svg?url');
  mask-repeat: no-repeat;
}

.price-up::after {
  background-color: theme('colors.p-green.400');
  rotate: theme('rotate.180');
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  mask: url('~/assets/icons/ChevronDown.svg?url');
  mask-repeat: no-repeat;
}

.price-normal::after {
  rotate: theme('rotate.180');
  display: block;
  background: theme('colors.n-gray.150');
  margin: auto 3px;
  width: 14px;
  height: 14px;
  content: '';
  mask: url('~/assets/icons/minus.svg?url');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
</style>
